<!--课程封面组件，部分完成，后续可以继续封装 -- 刘伟成 --7.9日-->
<template>
  <div class="courseCover">
    <div class="courseCover-course-logo">
      <img :src="resdata.img" alt />
    </div>
    <div class="courseCover-content">
      <p>{{ resdata.name }}</p>
      <template v-if="resdata.type === 2">
        <ul class="course-introduce">
          <li v-for="item in resdata.tags" :key="item">{{ item }}</li>
        </ul>
        <ul class="course-position">
          <li>{{ resdata.cityName }}</li>
          <li>{{resdata.address}}</li>
        </ul>
      </template>
      <template v-if="resdata.type === 1">
        <ul class="courseLeader flex">
          <li v-if="resdata.speaker">主讲人</li>
          <li v-if="resdata.speaker">|</li>
          <li>{{ resdata.speaker }}</li>
        </ul>
        <ul class="courseMedium flex">
          <li>
            <img :src="resdata.medium.img" alt />
          </li>
          <li>{{ resdata.medium.name}}</li>
        </ul>
      </template>
      <ul class="course-time">
        <li>{{ resdata.startTime }}</li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["resdata"],
  data: function () {
    return {};
  },
  mounted() {},
};
</script>

<style>
.courseCover {
  display: flex;
  padding: 16px 17px 18px 13px;
  width: 328px;
  height: 149px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}
.courseCover > .courseCover-course-logo > img {
  width: 86px;
  height: 115px;
  border-radius: 10px;
}
.courseCover > .courseCover-content {
  padding-left: 10px;
}
.courseCover > .courseCover-content > p {
  width: 202px;
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.courseCover > .courseCover-content > .course-introduce {
  margin-top: 12px;
  display: flex;
  font-size: 12.5px;
  flex-wrap: wrap;
  width: 140%;
  min-height: 40px;
  transform: scale(0.8);
  transform-origin: left center;
}

.courseCover > .courseCover-content > .course-introduce > li {
  box-sizing: content-box;
  padding: 5px 8px;
  margin-right: 10px;
  margin-bottom: 2px;
  height: 15px;
  background-color: #f3f3f3;
  border-radius: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 15px;
}

.courseCover > .courseCover-content > .course-position {
  display: flex;
  margin-bottom: 4px;
  width: 252.5px;
  font-size: 12.5px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 14px;
  transform: scale(0.8);
  transform-origin: left center;
}

.courseCover > .courseCover-content > .course-position li:nth-of-type(1) {
  border-right: 1px solid #666666;
  margin-right: 10px;
  padding-right: 10px;
}
.courseCover > .courseCover-content > .course-position li:nth-of-type(2) {
  white-space: nowrap;
  max-width: 192px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.courseCover > .courseCover-content > .course-time {
  display: flex;
  font-size: 12.5px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  transform: scale(0.8);
  transform-origin: left center;
}

.courseCover > .courseCover-content > .course-time > li {
  margin-right: 8px;
}
.courseCover > .courseCover-content > .courseLeader {
  height: 18px;
  width: 252.5px;
  margin-top: 8px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.courseCover > .courseCover-content > .courseLeader li {
  margin-right: 5px;
}
.courseCover > .courseCover-content > .courseMedium {
  height: 16px;
  margin: 20px 0px 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.courseCover > .courseCover-content > .courseMedium > li {
  margin-right: 6px;
}
.courseCover > .courseCover-content > .courseMedium > li img {
  width: 16px;
  height: 16px;
}
</style>